const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
const CHANGE_PAGE = "CHANGE_PAGE";
const HOME_PAGE_CLICKED_PRODUCT = "HOME_PAGE_CLICKED_PRODUCT";
const CLICKED_PRODUCT_NAME = "CLICKED_PRODUCT_NAME";

export {
  CHANGE_LANGUAGE,
  CHANGE_PAGE,
  HOME_PAGE_CLICKED_PRODUCT,
  CLICKED_PRODUCT_NAME
};
