import React, { useReducer, createContext, useEffect } from "react";
import {
  CHANGE_LANGUAGE,
  CHANGE_PAGE,
  HOME_PAGE_CLICKED_PRODUCT,
  CLICKED_PRODUCT_NAME
} from "../types";
import "./index.css";

export const GlobalStateContext = createContext();
export const GlobalDispatchContext = createContext();

const initialState = {
  language: "en",
  page: "",
  clickedProduct: "",
  nameGlobalClickedProduct: ""
};

function reducer(state, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE: {
      return {
        ...state,
        language: action.payload
      };
    }
    case CHANGE_PAGE: {
      return {
        ...state,
        page: action.payload
      };
    }
    case HOME_PAGE_CLICKED_PRODUCT: {
      return {
        ...state,
        clickedProduct: action.payload
      };
    }
    case CLICKED_PRODUCT_NAME: {
      return {
        ...state,
        nameGlobalClickedProduct: action.payload
      };
    }
    default:
      console.log("bad action");
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onLanguageChange = lang => {
    dispatch({ type: CHANGE_LANGUAGE, payload: lang });
  };

  useEffect(() => {
    var languageUrl = window.location.href;
    if (languageUrl.includes("/pl/")) {
      onLanguageChange("pl");
    } else if (languageUrl.includes("/dk/")) {
      onLanguageChange("dk");
    } else if (languageUrl.includes("/es/")) {
      onLanguageChange("es");
    } else if (languageUrl.includes("/de/")) {
      onLanguageChange("de");
    } else if (languageUrl.includes("/fr/")) {
      onLanguageChange("fr");
    }
  }, []);
  return (
    <GlobalStateContext.Provider value={state}>
      {/* <Helmet title={pageTitle} defer={false}>
        <html lang={state.language} amp />
        <meta charSet="utf-8" />
        {pageTitle && <title lang={state.language}>{pageTitle}</title>}
        {description && <meta name="description" content={description} />}
        <meta
          name="p:domain_verify"
          content="7d3473573c7f412d71564fda8ddaa969"
        />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={description} />
      </Helmet> */}
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
